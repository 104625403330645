<template>
    <div class="customer">
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">櫃台交接班清點表</div>
        </div>
        <div class="wrap">
            <BaseSelect
              title="月份"
              rootClass="handover-selector"
              name="handover-month"
              @selectChange="handleSelectChange"
              @init="$event.setValue(currentMonth)"
              :options="monthData"
            />
            <div class="row reserve-container">
                <div class="reserve-wrap">
                    <div class="order-list-wrap">
                        <div class="order-list-header">
                            <div class="row">
                                <div class="col-2">日期</div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -30px"
                                >
                                    櫃台
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -50px"
                                >
                                    公司手機
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -50px"
                                >
                                    刷卡單結帳單
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -50px"
                                >
                                    查看官網&公告
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -50px"
                                >
                                    現金
                                </div>
                            </div>
                            <div
                                class="row position-relative flex-nowrap"
                                style="left: -15px"
                            >
                                <div class="col-2">
                                    VIP券
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: 4px"
                                >
                                    公關券買券贈<br />客訴(C)
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: 8px"
                                >
                                    500券<br />檢客訴/贈券
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: 12px"
                                >
                                    1H抵用卡 (ZH)
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: 16px"
                                >
                                    熱石頭刮券 (FE)
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: 20px"
                                >
                                    200券 (ZF)
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: 20px"
                                >
                                    櫃台
                                </div>
                            </div>
                        </div>
                        <div class="list">
                            <transition appear name="fade" v-if="isListRequest">
                                <NoData
                                    :isFixed="false" 
                                    v-show="!currentList.data.length"
                                 />
                            </transition>
                            <transition-group appear name="fade" v-if="isListRequest">
                                <template
                                    v-for="(list, index) in currentList.data"
                                    :key="index"
                                    v-show="currentList.data.length"
                                >
                                    <div
                                        :class="{
                                            'order-list': true,
                                            'money-color':
                                                list.class_num == '早'
                                                    ? true
                                                    : false,
                                            blue:
                                                list.class_num == '晚'
                                                    ? true
                                                    : false,
                                        }"
                                    >
                                        <div class="row">
                                            <div class="col-2 pay-type-color">
                                                {{ list.date }}
                                            </div>
                                            <div
                                                class="col-2 position-relative"
                                                style="left: -30px"
                                            >
                                                {{ list.class_num }}
                                            </div>
                                            <div
                                                class="
                                                    col-2
                                                    d-flex
                                                    justify-content-center
                                                    align-items-center
                                                    position-relative
                                                "
                                                style="left: -50px"
                                            >
                                                <input
                                                    type="checkbox"
                                                    id="phone_1"
                                                    :checked="
                                                        list.copmpany_phone_status
                                                    "
                                                    disabled
                                                />
                                                <label for="phone_1"></label>
                                            </div>
                                            <div
                                                class="
                                                    col-2
                                                    d-flex
                                                    justify-content-center
                                                    align-items-center
                                                    position-relative
                                                "
                                                style="left: -50px"
                                            >
                                                <input
                                                    type="checkbox"
                                                    id="checkout_1"
                                                    :checked="list.credit_card"
                                                    disabled
                                                />
                                                <label for="checkout_1"></label>
                                            </div>
                                            <div
                                                class="
                                                    col-2
                                                    d-flex
                                                    justify-content-center
                                                    align-items-center
                                                    position-relative
                                                "
                                                style="left: -50px"
                                            >
                                                <input
                                                    type="checkbox"
                                                    id="look_1"
                                                    :checked="
                                                        list.watch_announcement
                                                    "
                                                    disabled
                                                />
                                                <label for="look_1"></label>
                                            </div>
                                            <div
                                                class="col-2 position-relative"
                                                style="left: -50px"
                                            >
                                                {{ isNaN(parseFloat(list.cash)) ? '-' : numberFormat(list.cash) }}
                                                <span class="unit">元</span>
                                            </div>
                                        </div>
                                        <div
                                            class="row position-relative flex-nowrap"
                                            style="left: -15px"
                                        >
                                            <div class="col-2" v-if="list.vip_coupons">
                                                {{
                                                    list.vip_coupons
                                                }}
                                                <span class="unit">本</span>
                                            </div>
                                            <div class="col-2" v-else>
                                                -
                                            </div>
                                            <div
                                                class="col-2 position-relative"
                                                style="left: 4px"
                                                v-if="list.buy_ticket"
                                            >
                                                {{
                                                    list.buy_ticket
                                                }}
                                                <span class="unit">張</span>
                                            </div>
                                            <div class="col-2" v-else>
                                                -
                                            </div>

                                            <div
                                                class="col-2 position-relative"
                                                style="left: 8px"
                                                v-if="list.five_hundred_coupons"
                                            >
                                                {{
                                                    list.five_hundred_coupons
                                                }}
                                                <span class="unit">張</span>
                                            </div>
                                            <div class="col-2" v-else>
                                                -
                                            </div>

                                            <div
                                                class="col-2 position-relative"
                                                style="left: 12px"
                                                v-if="list.one_hour"
                                            >
                                                {{
                                                    list.one_hour
                                                }}
                                                <span class="unit">張</span>
                                            </div>
                                            <div class="col-2" v-else>
                                                -
                                            </div>

                                            <div
                                                class="col-2 position-relative"
                                                style="left: 16px"
                                                v-if="list.hot_stone"
                                            >
                                                {{
                                                    list.hot_stone
                                                }}
                                                <span class="unit">張</span>
                                            </div>
                                            <div class="col-2" v-else>
                                                -
                                            </div>

                                            <div
                                                class="col-2 position-relative"
                                                style="left: 20px"
                                                v-if="list.two_hundred_coupons"
                                            >
                                                {{
                                                    list.two_hundred_coupons
                                                }}
                                                <span class="unit">張</span>
                                            </div>
                                            <div class="col-2" v-else>
                                                -
                                            </div>

                                            <div
                                                class="
                                                    col-2
                                                    pay-type-color
                                                    position-relative
                                                "
                                                style="left: 20px"
                                            >
                                                {{ list.member_name }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </transition-group>
                        </div>
                        <div class="list-bottom-wrap">
                            <div class="list-bottom">
                                <div class="list-bottom-header">
                                    <div class="row">
                                        <div class="col-2">日期</div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: -30px"
                                        >
                                            櫃台
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: -50px"
                                        >
                                            公司手機
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: -50px"
                                        >
                                            刷卡單結帳單
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: -50px"
                                        >
                                            查看官網&公告
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: -50px"
                                        >
                                            現金
                                        </div>
                                    </div>
                                    <div class="row position-relative flex-nowrap" style="left: -15px">
                                        <div class="col-2">VIP券</div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 4px"
                                        >
                                            公關券買券贈<br />客訴(C)
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 8px"
                                        >
                                            500券<br />檢客訴/贈券
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 12px"
                                        >
                                            1H抵用卡 (ZH)
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 16px"
                                        >
                                            熱石頭刮券 (FE)
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 20px"
                                        >
                                            200券 (ZF)
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 25px"
                                        ></div>
                                    </div>
                                </div>
                                <div
                                    :class="{
                                        'list-bottom-body': true,
                                        morning: values.class_num == '1' ? true : false,
                                        night: values.class_num == '2' ? true : false,
                                    }"
                                >
                                    <div class="row">
                                        <div class="col-2">
                                            {{
                                                new Date().getMonth() +
                                                1 +
                                                "." +
                                                new Date().getDate()
                                            }}
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: -30px"
                                        >
                                            <div class="select-wrap">
                                                <select
                                                    v-model="values.class_num"
                                                    name="class_num"
                                                >
                                                    <option :value="TIME_MORNING">{{TIME_MORNING_ZH}}</option>
                                                    <option :value="TIME_EVENING">{{TIME_EVENING_ZH}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div
                                            class="
                                                col-2
                                                d-flex
                                                justify-content-center
                                                align-items-center
                                                position-relative
                                            "
                                            style="left: -50px"
                                        >
                                            <input
                                                id="phone_bottom"
                                                type="checkbox"
                                                v-model="values.copmpany_phone_status"
                                                name="copmpany_phone_status"
                                                true-value="1"
                                                false-value="0"
                                            />
                                            <label for="phone_bottom"></label>
                                        </div>
                                        <div
                                            class="
                                                col-2
                                                d-flex
                                                justify-content-center
                                                align-items-center
                                                position-relative
                                            "
                                            style="left: -50px"
                                        >
                                            <input
                                                id="checkout_bottom"
                                                type="checkbox"
                                                v-model="values.credit_card"
                                                name="credit_card"
                                                true-value="1"
                                                false-value="0"
                                            />
                                            <label for="checkout_bottom"></label>
                                        </div>
                                        <div
                                            class="
                                                col-2
                                                d-flex
                                                justify-content-center
                                                align-items-center
                                                position-relative
                                            "
                                            style="left: -50px"
                                        >
                                            <input
                                                id="look_bottom"
                                                type="checkbox"
                                                v-model="values.watch_announcement"
                                                name="watch_announcement"
                                                true-value="1"
                                                false-value="0"
                                            />
                                            <label for="look_bottom"></label>
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: -50px"
                                        >
                                            <input
                                                type="text"
                                                v-model="values.cash"
                                                name="cash"
                                            />
                                        </div>
                                    </div>
                                    <div class="row position-relative flex-nowrap" style="left: -15px">
                                        <div class="col-2">
                                            <div class="input-wrap">
                                                <input
                                                    type="text"
                                                    v-model="values.vip_coupons"
                                                    name="vip_coupons"
                                                />
                                                <span>本</span>
                                            </div>
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 4px"
                                        >
                                            <div class="input-wrap">
                                                <input
                                                    type="text"
                                                    v-model="values.buy_ticket"
                                                    name="buy_ticket"
                                                />
                                                <span>張</span>
                                            </div>
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 8px"
                                        >
                                            <div class="input-wrap">
                                                <input
                                                    type="text"
                                                    v-model="values.five_hundred_coupons"
                                                    name="five_hundred_coupons"
                                                />
                                                <span>張</span>
                                            </div>
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 12px"
                                        >
                                            <div class="input-wrap">
                                                <input
                                                    type="text"
                                                    v-model="values.one_hour"
                                                    name="one_hour"
                                                />
                                                <span>張</span>
                                            </div>
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 16px"
                                        >
                                            <div class="input-wrap">
                                                <input
                                                    type="text"
                                                    v-model="values.hot_stone"
                                                    name="hot_stone"
                                                />
                                                <span>張</span>
                                            </div>
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 20px"
                                        >
                                            <div class="input-wrap">
                                                <input
                                                    type="text"
                                                    v-model="values.two_hundred_coupons"
                                                    name="two_hundred_coupons"
                                                />
                                                <span>張</span>
                                            </div>
                                            <ErrorMessage name="two_hundred_coupons" />
                                        </div>
                                        <div
                                            class="col-2 position-relative"
                                            style="left: 25px"
                                        >
                                            <button class="btn" @click="toSubmit">
                                                送出
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list-bottom-wrap">
                <div class="list-bottom">
                    <div class="list-bottom-header">
                        <div class="row">
                            <div class="col-2">日期</div>
                            <div
                                class="col-2 position-relative"
                                style="left: -30px"
                            >
                                櫃台
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: -50px"
                            >
                                公司手機
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: -50px"
                            >
                                刷卡單結帳單
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: -50px"
                            >
                                查看官網&公告
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: -50px"
                            >
                                現金
                            </div>
                        </div>
                        <div class="row position-relative flex-nowrap" style="left: -15px">
                            <div class="col-2">VIP券</div>
                            <div
                                class="col-2 position-relative"
                                style="left: 4px"
                            >
                                公關券買券贈<br />客訴(C)
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: 8px"
                            >
                                500券<br />檢客訴/贈券
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: 12px"
                            >
                                1H抵用卡 (ZH)
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: 16px"
                            >
                                熱石頭刮券 (FE)
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: 20px"
                            >
                                200券 (ZF)
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: 25px"
                            ></div>
                        </div>
                    </div>
                    <div
                        :class="{
                            'list-bottom-body': true,
                            morning: values.class_num == '1' ? true : false,
                            night: values.class_num == '2' ? true : false,
                        }"
                    >
                        <div class="row">
                            <div class="col-2">
                                {{
                                    new Date().getMonth() +
                                    1 +
                                    "." +
                                    new Date().getDate()
                                }}
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: -30px"
                            >
                                <div class="select-wrap">
                                    <select
                                        v-model="values.class_num"
                                        name="class_num"
                                    >
                                        <option :value="TIME_MORNING">{{TIME_MORNING_ZH}}</option>
                                        <option :value="TIME_EVENING">{{TIME_EVENING_ZH}}</option>
                                    </select>
                                </div>
                            </div>
                            <div
                                class="
                                    col-2
                                    d-flex
                                    justify-content-center
                                    align-items-center
                                    position-relative
                                "
                                style="left: -50px"
                            >
                                <input
                                    id="phone_bottom"
                                    type="checkbox"
                                    v-model="values.copmpany_phone_status"
                                    name="copmpany_phone_status"
                                    true-value="1"
                                    false-value="0"
                                />
                                <label for="phone_bottom"></label>
                            </div>
                            <div
                                class="
                                    col-2
                                    d-flex
                                    justify-content-center
                                    align-items-center
                                    position-relative
                                "
                                style="left: -50px"
                            >
                                <input
                                    id="checkout_bottom"
                                    type="checkbox"
                                    v-model="values.credit_card"
                                    name="credit_card"
                                    true-value="1"
                                    false-value="0"
                                />
                                <label for="checkout_bottom"></label>
                            </div>
                            <div
                                class="
                                    col-2
                                    d-flex
                                    justify-content-center
                                    align-items-center
                                    position-relative
                                "
                                style="left: -50px"
                            >
                                <input
                                    id="look_bottom"
                                    type="checkbox"
                                    v-model="values.watch_announcement"
                                    name="watch_announcement"
                                    true-value="1"
                                    false-value="0"
                                />
                                <label for="look_bottom"></label>
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: -50px"
                            >
                                <input
                                    type="text"
                                    v-model="values.cash"
                                    name="cash"
                                />
                            </div>
                        </div>
                        <div class="row position-relative flex-nowrap" style="left: -15px">
                            <div class="col-2">
                                <div class="input-wrap">
                                    <input
                                        type="text"
                                        v-model="values.vip_coupons"
                                        name="vip_coupons"
                                    />
                                    <span>本</span>
                                </div>
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: 4px"
                            >
                                <div class="input-wrap">
                                    <input
                                        type="text"
                                        v-model="values.buy_ticket"
                                        name="buy_ticket"
                                    />
                                    <span>張</span>
                                </div>
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: 8px"
                            >
                                <div class="input-wrap">
                                    <input
                                        type="text"
                                        v-model="values.five_hundred_coupons"
                                        name="five_hundred_coupons"
                                    />
                                    <span>張</span>
                                </div>
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: 12px"
                            >
                                <div class="input-wrap">
                                    <input
                                        type="text"
                                        v-model="values.one_hour"
                                        name="one_hour"
                                    />
                                    <span>張</span>
                                </div>
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: 16px"
                            >
                                <div class="input-wrap">
                                    <input
                                        type="text"
                                        v-model="values.hot_stone"
                                        name="hot_stone"
                                    />
                                    <span>張</span>
                                </div>
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: 20px"
                            >
                                <div class="input-wrap">
                                    <input
                                        type="text"
                                        v-model="values.two_hundred_coupons"
                                        name="two_hundred_coupons"
                                    />
                                    <span>張</span>
                                </div>
                                <ErrorMessage name="two_hundred_coupons" />
                            </div>
                            <div
                                class="col-2 position-relative"
                                style="left: 25px"
                            >
                                <button class="btn" @click="toSubmit">
                                    送出
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FixedOrderInfo/>
</template>

<script>
import { ref, onMounted, inject, computed, watch, reactive } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
import { numberFormat } from "@/js/functions";
import Logo from "@/components/includes/Logo.vue";
import NoData from "@/components/includes/NoData.vue";
import { useForm, useSubmitForm } from "vee-validate";
import { positiveInteger, checkBoolean, checkInOptions } from "../js/rules";
import { flashMessage } from "@/js/swal";
import Swal from "sweetalert2";
import * as yup from "yup";
import FixedOrderInfo from '@/components/inline/FixedOrderInfo';

export default {
    name: "HandoverRecord",
    components: {
        Logo,
        NoData,
        FixedOrderInfo
    },
    setup() {
        const { state, commit, dispatch } = useStore();

        const isListRequest = ref(false);

        const storeId = computed(() => state.auth.store_id).value;

        // 常數
        const TIME_MORNING = 1;
        const TIME_EVENING = 2;
        const TIME_MORNING_ZH = '早';
        const TIME_EVENING_ZH = '晚';

        let formData = reactive({
            class_num: 1,
            copmpany_phone_status: 0,
            credit_card: 0,
            watch_announcement: 0,
            cash: 0,
            buy_ticket: 0,
            five_hundred_coupons: 0,
            one_hour: 0,
            hot_stone: 0,
            two_hundred_coupons: 0,
            vip_coupons: 0
        });

        const monthData = ref([
            {
                'id':'1',
                'name':'1月'
            },
            {
                'id':'2',
                'name':'2月'
            },
            {
                'id':'3',
                'name':'3月'
            },
            {
                'id':'4',
                'name':'4月'
            },
            {
                'id':'5',
                'name':'5月'
            },
            {
                'id':'6',
                'name':'6月'
            },
            {
                'id':'7',
                'name':'7月'
            },
            {
                'id':'8',
                'name':'8月'
            },
            {
                'id':'9',
                'name':'9月'
            },
            {
                'id':'10',
                'name':'10月'
            },
            {
                'id':'11',
                'name':'11月'
            },
            {
                'id':'12',
                'name':'12月'
            },
        ])

        // 搜尋的當下月份
        let currentMonth = ref(new Date().getMonth() + 1);

        // 搜尋下的清單資料
        let currentList = reactive({
            data: [],
        });

        const validationSchema = yup.object({
            class_num: yup
                .number()
                .required("必填")
                .test(...checkInOptions([1, 2], "班別僅能為早或晚")),
            copmpany_phone_status: yup
                .number()
                .required("必填")
                .test(...checkBoolean()),
            credit_card: yup
                .number()
                .required("必填")
                .test(...checkBoolean()),
            watch_announcement: yup
                .number()
                .required("必填")
                .test(...checkBoolean()),
            cash: yup
                .number()
                .required("必填")
                .test(...positiveInteger()),
            buy_ticket: yup
                .number()
                .required("必填")
                .test(...positiveInteger()),
            five_hundred_coupons: yup
                .number()
                .required("必填")
                .test(...positiveInteger()),
            one_hour: yup
                .number()
                .required("必填")
                .test(...positiveInteger()),
            hot_stone: yup
                .number()
                .required("必填")
                .test(...positiveInteger()),
            two_hundred_coupons: yup
                .number()
                .required("必填")
                .test(...positiveInteger()),
            vip_coupons: yup
                .number()
                .required("必填")
                .positive("必須是正數")
                .test(
                    "is-decimal",
                    "只能包含最多一位小數",
                    (value) => value === undefined || /^[0-9]+(\.[0-9]{1})?$/.test(value.toString())
                ),
        });

        const { errors, values, resetForm, setFieldValue, setValues } = useForm({
            validateOnMount: false,
            validationSchema,
            initialValues: formData,
        });

        watch(currentMonth, () => {
            searchMonth();
        });

        watch(errors, (newVal) => {
            console.log("errors: ", newVal);
        });

        const handleSelectChange = (selectVal) => {
            currentMonth.value = selectVal;
        };


        const today = new Date();
        const todayY = today.getFullYear();
        const todayM = today.getMonth() + 1;
        const todayD = today.getDate();

        // 自動帶入資料
        const autoCompleteData = computed(()=>{
            var result = {};

            currentList.data.some((aDay)=>{
                var aDayDateArr = aDay.date.split('.');
                var aDayY = parseInt(aDayDateArr[0]);
                var aDayM = parseInt(aDayDateArr[1]);
                var aDayD = parseInt(aDayDateArr[2]);
                var aDayClassZH = aDay.class_num === TIME_MORNING_ZH ? TIME_MORNING : TIME_EVENING

                if (
                    todayY === aDayY &&
                    todayM === aDayM &&
                    todayD === aDayD &&
                    aDayClassZH === values.class_num
                ) {
                    result = aDay
                }
            });

            return result
        });

        watch(autoCompleteData, ()=>{
            if ( Object.keys(autoCompleteData.value).length == 0) {
                // 沒有需要帶入資料
                for(let key in values){
                    if (key !== 'class_num' && key !== 'handover-month') {
                        setFieldValue(key, 0)
                    }
                }

            } else {
                // 有資料可帶入

                for(let key in values){
                    var val = autoCompleteData.value[key]
                    if ( val !== undefined ) {
                        if ( key === 'class_num') {
                            // 設值select。不能是中文
                            setFieldValue(key, val === TIME_MORNING_ZH ? TIME_MORNING : TIME_EVENING)
                        } else {
                            setFieldValue(key, val)
                        }
                    }
                }
            }

        })

        // 搜尋月份
        const searchMonth = async () => {
            let res = await basePost("api_shift/shift_list", {
                store_id: storeId,
                year: new Date().getFullYear(),
                month: currentMonth.value,
            });

            let { status, data } = res;

            if (status === 200 && data.res_code === 1) {
                let { res_data } = data;
                currentList.data = res_data.answer_list;
            }
        };

        searchMonth();

        // 防呆 - 至少填寫一個欄位
        function checkBeforeSubmit(){
            var pass = false;

            for(let key in values){
                if (values[key] !== 0 && values[key] !== undefined && key !== 'class_num' && key !== 'handover-month') {
                    pass = true
                }
            }


            if ( !pass ) {
                flashMessage('請至少填寫一個欄位!', 'info')
            }

            return pass;
        }

        const toSubmit = useSubmitForm(async (values, actions) => {

            if ( !checkBeforeSubmit() ) return;

            let res = await basePost("api_shift/shift_checked", {
                store_id: storeId,
                ...values,
            });

            const { data, status } = res;
            if (status == 200) {
                Swal.fire({
                    title: data.res_content,
                    icon: data.res_code == 1 ? "success" : "error",
                    confirmButtonText: "確定",
                }).then(() => {
                    if (currentMonth.value == new Date().getMonth() + 1) {
                        searchMonth();
                    }
                });
            }
        });

        watch(currentList, () => {
            if(!isListRequest.value) {
                isListRequest.value = true;
            }
        })

        return {
            isNaN,
            parseFloat,
            currentMonth,
            currentList,
            autoCompleteData,
            toSubmit,
            formData,
            values,
            handleSelectChange,
            isListRequest,
            numberFormat,
            TIME_MORNING,
            TIME_EVENING,
            TIME_MORNING_ZH,
            TIME_EVENING_ZH,
            monthData
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.customer {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            a {
                text-decoration: underline !important;
                font: normal normal bold 20px/27px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #ffffff;
            }
        }
    }
    .wrap {
        max-width: 1625px;
        margin: 0 auto;
        position: relative;
        top: -72px;
        .input-group {
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ffffff;
            border-radius: 11px;
            height: 53px;
            width: 300px;
            position: absolute;
            right: -5px;
            top: -67px;
            @include phone {
                height: 45px;
                position: relative;
                top: 0;
                right: 0;
            }
            .input-group-text {
                background: transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                border: none;
                border-radius: 11px 0px 0px 11px;
                padding: 13px;
                padding-right: 25px;
                position: relative;
                font-weight: bold;
                &:after {
                    content: "";
                    position: absolute;
                    border-right: 1px solid #eee;
                    top: 10px;
                    bottom: 10px;
                    right: 0;
                    z-index: 1;
                }
                @include phone {
                    padding: 12px;
                }
            }
            .form-control {
                background: transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                border: none;
                border-radius: 0px 11px 11px 0px;
                padding: 14px;
                font: normal normal 600 16px/22px Open Sans;
            }
            select {
                flex: 1;
                background: #f9f9f9
                    url("~@/assets/tgd/lightbox/select-arrow-box.svg") no-repeat
                    101%;
                border: none;
                border-radius: 11px;
                padding: 14px;
                padding-left: 25px;
                font: normal normal 600 16px/22px Open Sans;
                appearance: none;
                filter: contrast(100%);
                transition: all 0.3s;
                &:hover {
                    filter: contrast(130%);
                }
                @include phone {
                    padding: 12px;
                }
            }
        }
        .reserve-container {
            justify-content: center;
            width: 100%;
            overflow: overlay;
            position: relative;
            margin: 0;
            .reserve-wrap {
                position: relative;
                width: unset;
                padding: 0;
                width: 1625px;
                .reserve-title {
                    position: relative;
                    top: -40px;
                    margin-bottom: 20px;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0;
                    color: #0f2a68;
                    @include pad_pro {
                        margin: 20px 0 15px;
                    }
                }
                .money-color {
                    color: #a4641a;
                    @include custom-checkbox(
                        "~@/assets/tgd/handover/check_none.svg",
                        "~@/assets/tgd/handover/check_brown.svg",
                        10px
                    );
                }
                .pay-type-color {
                    color: #707070;
                }
                .blue {
                    color: #0f2a68;
                    @include custom-checkbox(
                        "~@/assets/tgd/handover/check_none.svg",
                        "~@/assets/tgd/handover/check_blue.svg",
                        10px
                    );
                }
            }
            .order-list-wrap {
                margin-bottom: 15px;
                box-shadow: 0px 34px 77px #00000042;
                border: 1px solid #ffffff;
                border-radius: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                width: 1625px;
                &:hover {
                    filter: contrast(105%);
                }
                .list {
                    height: 700px;
                    overflow-y: auto;
                    padding: 0px 30px 20px;
                    margin: 0 30px;
                    font: normal normal bold 16px/22px Open Sans;
                    &::-webkit-scrollbar-track {
                        background-color: #d6d6d6;
                        border-radius: 2px;
                    }
                    &::-webkit-scrollbar {
                        width: 7px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #707070;
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 9px;
                    }
                    @media (max-width: 1700px) {
                        height: auto;
                        padding-bottom: 200px;
                    }
                }
            }
            .order-list-wrap {
                background: transparent
                    linear-gradient(
                        122deg,
                        #ffffff 0%,
                        #d9d9d9 18%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                padding: 70px 0 0;
                font: normal normal bold 14px/21px Microsoft JhengHei;
                line-height: 21px;
                @include pad_pro {
                    margin-bottom: 15px;
                }
                .col-2 {
                    flex: 0 1 auto;
                    padding: 0 6px;
                    text-align: center;
                    .unit {
                        font: normal normal bold 12px/16px Microsoft JhengHei;
                        letter-spacing: 0px;
                        color: #707070;
                    }
                }
                .order-list-header {
                    position: relative;
                    top: -25px;
                    display: flex;
                    line-height: 19px;
                    padding: 0 34.5px;
                    margin: 0 30px;
                    .row {
                        flex: 1;
                        align-items: center;
                        flex-wrap: wrap;
                        .col-2 {
                            padding: 0 6px;
                            flex: 0 1 auto;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                        }
                    }
                    @include pad_pro {
                        top: -15px;
                    }
                }
                .order-list {
                    padding: 15px 0;
                    align-items: center;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    display: flex;
                    .row {
                        flex: 1;
                        align-items: center;
                    }
                    &:hover {
                        filter: contrast(90%);
                    }
                    &:nth-child(odd) {
                        background: rgba(255, 255, 255, 0.2);
                    }
                    &:nth-child(even) {
                        background: rgba(238, 238, 238, 0.2);
                    }
                    label {
                        margin: 0;
                    }
                    .btn-cancel {
                        background: #a2a2a2;
                        border-radius: 21px;
                        text-decoration: underline;
                        font: normal normal bold 14px/19px Microsoft JhengHei;
                        letter-spacing: 0px;
                        color: #ffffff;
                        width: 113px;
                        padding: 8px 0;
                        display: flex;
                        align-content: center;
                        justify-content: center;
                        cursor: pointer;
                        @include hover(90%);
                        img {
                            padding-right: 5px;
                        }
                    }
                }
            }
            .list-bottom-wrap {
                display: none;
                position: absolute;
                width: 100%;
                @media (max-width: 1700px) {
                    display: block;
                }

            }
        }
        .list-bottom-wrap {
            position: fixed;
            bottom: 0;
            width: calc(100% - 40px);
            @media (max-width: 1700px) {
                display: none;
            }
            .list-bottom {
                padding: 20px 64.5px;
                background: #f2f3f4fa 0% 0% no-repeat padding-box;
                box-shadow: 0px 7px 83px #00000042;
                border: 1px solid #ffffff;
                border-radius: 0px 0px 19px 19px;
                width: 1625px;
                @include pad_pro_lg {
                    box-shadow: unset;
                }
                .list-bottom-header,
                .list-bottom-body {
                    display: flex;
                    padding: 12px 0;
                    .row {
                        align-items: center;
                        flex: 1;
                        text-align: center;
                        .col-2 {
                            padding: 0 6px;
                            flex: 0 1 auto;
                        }
                    }
                }
                .list-bottom-header {
                    font: normal normal bold 14px/19px Microsoft JhengHei;
                }
                .list-bottom-body {
                    font: normal normal bold 16px/22px Open Sans;
                    select {
                        font: normal normal bold 16px/22px Open Sans;
                        width: 32%;
                        border: none;
                        @include select(
                            #f2f3f4fa,
                            "~@/assets/tgd/handover/select.svg",
                            right
                        );
                    }
                    label {
                        margin: 0;
                    }

                    input[type="text"] {
                        width: 100px;
                        height: 34px;
                        border: 1px solid #4a4a4a;
                        border-radius: 4px;
                        text-align: center;
                        font: normal normal bold 16px/22px Open Sans;
                        background: transparent;
                    }
                    .input-wrap {
                        display: flex;
                        position: relative;
                        input {
                            padding-left: 5px;
                            padding-right: 20px;
                        }
                        span {
                            position: absolute;
                            right: 15px;
                            bottom: 4px;
                            font-size: 12px;
                        }
                    }
                    .btn {
                        background: transparent
                            linear-gradient(
                                293deg,
                                #a4641a 0%,
                                #a4641a 64%,
                                #ebc496 100%
                            )
                            0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #00000029;
                        border: 1px solid #ffffff85;
                        border-radius: 6px;
                        font: normal normal bold 14px/19px Microsoft JhengHei;
                        letter-spacing: 0px;
                        color: #ffffff;
                        width: 78px;
                        height: 37px;
                        @include hover(140%);
                    }
                }
                .morning {
                    select {
                        color: #a4641a;
                    }
                    .col-2 {
                        @include custom-checkbox(
                            "~@/assets/tgd/handover/check_none.svg",
                            "~@/assets/tgd/handover/check_brown.svg",
                            10px
                        );
                    }
                    input[type="text"] {
                        color: #a4641a;
                        &:focus {
                            border: 1px solid #a4641a;
                        }
                    }
                }
                .night {
                    select {
                        color: #0f2a68;
                    }
                    .col-2 {
                        @include custom-checkbox(
                            "~@/assets/tgd/handover/check_none.svg",
                            "~@/assets/tgd/handover/check_blue.svg",
                            10px
                        );
                    }
                    input[type="text"] {
                        color: #0f2a68;
                        &:focus {
                            border: 1px solid #0f2a68;
                        }
                    }
                }
            }
        }
    }
}

:deep(.handover-selector) {
    max-width: 295px;
    margin-left: auto;

}
</style>
