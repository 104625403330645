<template>
    <transition appear name="fade">
        <div class="mask" v-show="isShow" @click="handleCloseModal"></div>
    </transition>
    <transition appear name="fade">
        <div class="punch-process-lightbox" v-show="isShow">
            <div class="side-menu-close" @click="handleCloseModal">
                <img src="@/assets/tgd/lightbox/close.svg" alt="" />
            </div>
            <div class="time-wrapper">
                <div class="time-item">
                    <p class="time-subject">下班時間</p>
                    <p class="time-txt">{{ leaveTime || '-' }}</p>
                </div>
                <div class="time-item">
                    <p class="time-subject">結束加班時間</p>
                    <p class="time-txt">
                        <Datepicker
                            v-model="time"
                            :startTime="initTime"
                            enableTimePicker
                            format="HH:mm"
                            hideInputIcon
                            timePicker
                            :clearable="false"
                        />
                    </p>
                </div>
            </div>
            <div
                class="can-sign-in-8"
                v-if="canSignIn8"
            >
                <BaseChoice
                    type="checkbox"
                    customClassName="w-check-icon"
                    iconBgColor="#ab7e2b"
                    :value="isSignIn8"
                    @eventClick="(val)=>{
                       isSignIn8 = val;
                    }"
                >
                    今天要簽八
                </BaseChoice>
            </div>
            <button class="btn" type="button" @click="handleSend">
                <img src="@/assets/tgd/aroma/time.svg" alt="" />
                送出
            </button>
        </div>
    </transition>
</template>

<script>
import { ref, computed, watch } from "vue";
import moment from "moment";

export default {
    name: "PunchProcessLightbox",
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
        leaveTime: String,
        canSignIn8: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["closeModal", "send"],
    setup(props, { emit }) {
        const leaveTime = computed(() => props.leaveTime);
        const isSignIn8 = ref(false)

        const time = ref({
            hours: moment().hours(),
            minutes: moment().minutes() || 0, 
        });
        const initTime = ref({
            hours: moment().hours(),
            minutes: moment().minutes() || 0,
        });

        const handleCloseModal = () => {
            emit("closeModal", false);
        };

        const handleSend = () => {
            if (!time.value.hours) {
                console("Time is not fully set: ", time.value);
                return;
            }

            const hours = time.value.hours < 10 ? `0${Number(time.value.hours)}` : time.value.hours;
            const minutes = time.value.minutes < 10 ? `0${Number(time.value.minutes)}` : time.value.minutes || "00";

            emit("send", {
                    over_time: `${hours}:${minutes}`,
                },
                isSignIn8.value ? 1 : 0,
            );
        };

        watch([leaveTime, () => props.isShow], (arr) => {
            if (arr[0] && arr[0].includes(':')) {
                const splitedLeaveTime = arr[0].split(":");

                initTime.value.hours = time.value.hours = splitedLeaveTime[0];
                initTime.value.minutes = time.value.minutes = splitedLeaveTime[1] || 0;
            } else {
                console.warn("Invalid leaveTime format: ", arr[0]);
            }
        });

        watch(()=>props.isShow, isShow => {
            if(!isShow) {
                isSignIn8.value = false;
            }
        })

        return {
            time,
            initTime,
            handleCloseModal,
            handleSend,
            isSignIn8
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.punch-process-lightbox {
    max-width: 948px;
    width: 100%;
    margin: 0 20px;
    padding: 5.7% 7%;
    border-radius: 15px;
    box-shadow: 0px 34px 77px 0 rgba(0, 0, 0, 0.41);
    background: linear-gradient(
        117deg,
        rgba(255, 255, 255, 1),
        rgba(229, 229, 229, 1),
        rgba(255, 255, 255, 1)
    );
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;

    .side-menu-close {
        border: 2px solid rgb(255, 255, 255);
        background: rgba(0, 0, 0, 1);
    }

    .time-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 44px;
    }

    .can-sign-in-8 {
        display: flex;
        justify-content: center;
    }

    .time-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc(100% / 2 - 5px);
        padding: 35px 15px;
        border-radius: 11px;
        box-shadow: -3px -4px 8px 0 rgba(255, 255, 255, 0.61);
        background: linear-gradient(
            180deg,
            rgba(236, 236, 236, 1),
            rgba(249, 249, 249, 1),
            rgba(255, 255, 255, 1)
        );

        &:first-child {
            .time-txt {
                color: rgba(74, 74, 74, 0.33);
            }
        }
    }

    .time-subject {
        font-size: 16px;
        font-weight: 900;
        line-height: 21px;
        color: rgba(0, 0, 0, 1);
    }

    .time-txt,
    :deep(.dp__pointer) {
        font-family: "Open Sans", sans-serif;
        font-size: 40px;
        font-weight: 900;
        line-height: 55px;
    }

    .btn {
        margin: 0 auto;
    }

    :deep(.dp__pointer) {
        padding: 0 !important;
        text-align: center;
        background: transparent;
        color: rgba(183, 127, 26, 1) !important;
    }
}

.btn {
    background: transparent
        linear-gradient(
            108deg,
            #ab7e2b 0%,
            #f1e3b5 14%,
            #ab7e2b 61%,
            #c48109 100%
        )
        0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ffffff;
    border-radius: 15px;
    font: normal normal bold 21px/28px Microsoft JhengHei;
    letter-spacing: 0px;
    color: #ffffff;
    width: 211px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include hover(130%);
    @include phone {
        width: 200px;
        height: 53px;
        font-size: 16px;
    }
    img {
        padding-right: 10px;
        width: 45px;
    }
    &:hover {
        color: rgb(255, 255, 255);
    }
}
</style>
